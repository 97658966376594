import React, { useCallback } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
} from "@nextui-org/react";
import { IoClose } from "react-icons/io5";
import { useQuery } from "react-query";
import { getCredentials } from "../../views/Home/api/HomeAPi";
import { Loading } from "../../../../components/Loading";
import { useNavigate } from "react-router-dom";
import { FaRegFileLines } from "react-icons/fa6";

export const SearchTotal = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [data, setData] = React.useState<any>([]);
  const [search, setSearch] = React.useState("");
  const [value, setValues] = React.useState("");
  const { isLoading } = useQuery(
    ["credentialsSearch", { search: search }],
    () => {
      return getCredentials({ search });
    },
    {
      onSuccess: ({ data: { data } }) => {
        setData(data);
      },
      refetchOnWindowFocus: false,
      staleTime: 0,
      enabled: isOpen,
      cacheTime: 0, // Los datos no se almacenan en caché
    }
  );

  let timeOut: any = null;

  function handleInputChange(text: string) {
    if (timeOut) clearTimeout(timeOut);
    timeOut = setTimeout(() => {
      setSearch(text);
    }, 1000);
  }

  const setterValues = useCallback((text: string) => {
    setValues(text);
  }, []);

  return (
    <>
      <Input
        type="text"
        variant="bordered"
        placeholder="Buscador..."
        onClick={() => setIsOpen(true)}
        value={value}
        className="w-[500px] cursor-pointer"
        endContent={
          <button
            className="bg-transparent hover:text-primary"
            onClick={() => setValues("")}
          >
            <IoClose />
          </button>
        }
      />
      <Modal
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        placement="top-center"
        size="2xl"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Buscador
              </ModalHeader>
              <ModalBody>
                <Input
                  autoFocus
                  size="md"
                  placeholder="Escribe para buscar la credencial..."
                  variant="bordered"
                  onValueChange={handleInputChange}
                  color="secondary"
                />
                <div className="mt-4 mb-4">
                  {isLoading ? (
                    <div className="flex justify-center items-center">
                      <Loading />
                    </div>
                  ) : (
                    <Content
                      filteredCommands={data}
                      onClose={onClose}
                      onSetterValue={setterValues}
                    />
                  )}
                </div>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

interface PropsContent {
  onClose: () => void;
  onSetterValue: (newValue: string) => void;
  filteredCommands: any;
}

const Content = ({
  filteredCommands,
  onClose,
  onSetterValue,
}: PropsContent) => {
  const navigate = useNavigate();
  const handleNavigate = (id: number, user: string) => {
    const url = `/credentials/${id}/update/`;
    navigate(url);
    onSetterValue(user);
    onClose();
  };
  return (
    <>
      {filteredCommands.map((command: any) => (
        <div
          className="relative flex flex-row py-2 mb-3 pl-3 group bg-gray-100 hover:bg-primary/40 cursor-pointer rounded-lg overscroll-auto"
          key={command.id}
          onClick={() => handleNavigate(command.id, command.user)}
        >
          <div className="mr-3 flex items-center justify-center">
            <FaRegFileLines   className="h-5 w-5"/>
          </div>
          <div className="flex flex-col ">
            <div className="font-medium text-xs text-primary mb-1 sm:mb-0">
              {command.sub_category.name}
            </div>

            <div className="items-start mb-1 group-last:before:hidden ">
              <div className="text-sm font-bold text-slate-900">
                {command.user}
              </div>
            </div>
          </div>
        </div>
      ))}
      {filteredCommands.length === 0 && <NotFound />}
    </>
  );
};

const NotFound = () => {
  return (
    <div>
      <div className="no-file-found flex flex-col items-center justify-center py-8 px-4 text-center dark:bg-gray-800 ">
        <svg
          className="w-12 h-12 dark:text-gray-400 text-gray-400"
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 24 24"
          height="200px"
          width="200px"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="File_Off">
            <g>
              <path d="M4,3.308a.5.5,0,0,0-.7.71l.76.76v14.67a2.5,2.5,0,0,0,2.5,2.5H17.44a2.476,2.476,0,0,0,2.28-1.51l.28.28c.45.45,1.16-.26.7-.71Zm14.92,16.33a1.492,1.492,0,0,1-1.48,1.31H6.56a1.5,1.5,0,0,1-1.5-1.5V5.778Z"></path>
              <path d="M13.38,3.088v2.92a2.5,2.5,0,0,0,2.5,2.5h3.07l-.01,6.7a.5.5,0,0,0,1,0V8.538a2.057,2.057,0,0,0-.75-1.47c-1.3-1.26-2.59-2.53-3.89-3.8a3.924,3.924,0,0,0-1.41-1.13,6.523,6.523,0,0,0-1.71-.06H6.81a.5.5,0,0,0,0,1Zm4.83,4.42H15.88a1.5,1.5,0,0,1-1.5-1.5V3.768Z"></path>
            </g>
          </g>
        </svg>
        <h3 className="text-xl font-medium mt-4 text-gray-400 dark:text-gray-200">
          No hay resultado
        </h3>
      </div>
    </div>
  );
};
