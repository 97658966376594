import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  NavbarItem,
  Link,
  Button,
  Input,
} from "@nextui-org/react";
import { useThemeMovilPay } from "../../../hooks/useTheme";
import { CheckChangeTheme } from "./CheckChangeTheme";
import { Img } from "react-image";
import { LuLogOut } from "react-icons/lu";
import { configLogout } from "../../../utils/configLogout";
import { AuthContext } from "../../../context/AuthProviders";
import { useContext } from "react";
import { IoIosSearch } from "react-icons/io";
import { SearchTotal } from "./navbar/SearchTotal";

export const NavbarCore = ({
  children,
}: {
  children: React.ReactNode;
  path?: React.ReactNode;
}) => {
  const { setToken } = useContext(AuthContext);
  const { darkMode } = useThemeMovilPay();
  const img = darkMode ? "logo-white" : "gonavi";

  const handleLogout = () => {
    configLogout();
    setToken(false);
  };

  return (
    <Navbar
      maxWidth="full"
      className={`${
        darkMode
          ? "bg-transparent border-b-2 border-primaryDark"
          : "border-b-2 border-gray-300"
      } shadow-md `}
      style={{ zIndex: 10 }}
    >
      <NavbarBrand>
        <a href="/credentials/" className="hidden sm:flex sm:flex-col  items-center justify-center">
          <Img
            src={require(`../../../assets/img/${img}.png`)}
            className="h-10 w-38"
          />
        </a>
        <div className="flex lg:ml-10">{children}</div>
      </NavbarBrand>
      <NavbarContent className="hidden sm:flex " justify="center">
        <NavbarItem>
          <SearchTotal />
        </NavbarItem>
      </NavbarContent>
      <NavbarContent justify="end">
        <NavbarItem className="">
          <CheckChangeTheme />
        </NavbarItem>
        <NavbarItem>
          <Button
            as={Link}
            color="danger"
            size="sm"
            variant="ghost"
            onPress={handleLogout}
          >
            Salir <LuLogOut className="h-4 w-4" />
          </Button>
        </NavbarItem>
      </NavbarContent>
    </Navbar>
  );
};
