import { deleteCookie } from "../config/cookies";


export const configLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    deleteCookie("token")
    deleteCookie("user")
    deleteCookie("last_path_info")
    window.location.href = "/auth/";
    
}
